import React, { useState } from "react";
import owen from './owen.png';
import sheep from './sheep.png';
import DragMove from './Move.js';
import Draggable from 'react-draggable';

import './App.css';

function App() {



  const [translate, setTranslate] = useState({
    x: 0,
    y: 0
  });

  const handleDragMove = (e) => {
    setTranslate({
      x: translate.x + e.movementX,
      y: translate.y + e.movementY
    });
  };




  return (
    <div className="main">

      <div className="main-lamb">

        <Draggable
          handle=".lamb-logo-container"
          defaultPosition={{x: 0, y: 0}}
          position={null}
          grid={[25, 25]}
          scale={1}
          // onStart={this.handleStart}
          // onDrag={this.handleDrag}
          // onStop={this.handleStop}
          >
          <div className="lamb-logo-container">
            <img  draggable="false" src={sheep} className="lamb-logo" alt="lamb-logo" />
          </div>
        </Draggable>

      

        {/* <DragMove onDragMove={handleDragMove}>
          <div
            style={{
              transform: `translateX(${translate.x}px) translateY(${translate.y}px)`
            }}
          >
            <img src={sheep} className="lamb-logo" alt="logo" />
          </div>
        </DragMove> */}
      </div>

        
     </div>
  );
}

export default App;




// background-image: url("xp.png");
